@font-face {
  font-family: real text pro;
  src: url(fonts/RealTextPro-Bold.eot);
  src: url(fonts/RealTextPro-Bold.eot?#iefix) format("embedded-opentype"),url(fonts/RealTextPro-Bold.woff2) format("woff2"),url(fonts/RealTextPro-Bold.woff) format("woff"),url(fonts/RealTextPro-Bold.ttf) format("truetype"),url(fonts/RealTextPro-Bold.svg#RealTextPro-Bold) format("svg");
  font-weight: 700;
  font-style: normal;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
i,
select {
    margin: 0;
    padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Segoe UI', Roboto, Oxygen, 'Helvetica Neue', sans-serif;
}

body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #001730;

            
}

img {
    width: 200px;
}

a {
    cursor: pointer;
    outline: 0;
    width: 270px;
    height: 48px;
    border-radius: 8px;
    background-color: #001730;
    color:#fff;
    margin-top: 40px;
    -webkit-transform: scale(.7);
            transform: scale(.7);
    text-decoration:none;
}

a:hover, a:visited, a:active,a:focus {
    text-decoration:none;
    color:#fff;
}

h1 {
  color:#fff;
  font-family: "Real Text Pro",'Segoe UI',Arial,sans-serif;
}

.box-1:hover a::before {
    background-color: #00BF9C;
}

.box-2:hover a::before {
    background-color: #653EE6;
}

.box-3:hover a::before {
    background-color: #008BFF;
}

.box-4:hover a::before {
    background-color: #FF6500;
}

.container-header {
  text-align:center;
  margin-top:15vh;
  margin-bottom:10vh;
}

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 80vw;
    height: 50vh;
}

.box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    will-change: transform;
    margin-bottom:40px
}

.box:hover .cover {
    -webkit-transform: translateY(-14px) scale(1.04);
            transform: translateY(-14px) scale(1.04);
}

.box-1 a {
    border: 1px solid #00BF9C;
    color: #fff;
    font-size: 22px;
    text-align: center;
    padding-right: 0;
    line-height: 40px;
}

.box-1 a::after {
    content: "";
    background-color: #00BF9C;
    top: -85px
}

.box-2 a {
    border: 1px solid #653EE6;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
}

.box-2 a::after {
    content: "";
    background-color: #653EE6;
    top: -85px
}

.box-3 a {
    border: 1px solid #008BFF;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
}

.box-3 a::after {
    content: "";
    background-color: #008BFF;
    top: -85px
}

.box-4 a {
    border: 1px solid #FF6500;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
}

.box-4 a::after {
    content: "";
    background-color: #FF6500;
    top: -85px
}

.cover {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    will-change: transform;
}

.cover img {
    -webkit-transition: all 260ms ease-in-out;
    transition: all 260ms ease-in-out;
}

.box .cover::after {
    content: "";
    z-index: -99;
    position: absolute;
    top: 20px;
    left: -20px;
    display: block;
    width: 200px;
    height: 269px;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px 269px;
    -webkit-filter: blur(24px);
            filter: blur(24px);
    -webkit-transition: all 260ms ease-in-out;
    transition: all 260ms ease-in-out;
    will-change: transform;
    -webkit-transform: scale(.6);
            transform: scale(.6);
}

.box:hover .cover::after {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.box-1 .cover::after {
    background-image: url('img/ebs-shadow.png');
}

.box-2 .cover::after {
    background-image: url('img/grow-smarter-shadow.png');
}

.box-3 .cover::after {
    background-image: url('img/future-foundations-shadow.png');
}

.box-4 .cover::after {
    background-image: url('img/global-defence-shadow.png');
}

.box-1:hover a div::before {
    color: #fff;
}

.box-2:hover a div::before {
    color: #fff;
}


.box-3:hover a div::before {
    color: #fff;
}


.box-4:hover a div::before {
    color: #fff;
}

.dr {
position: absolute;
  bottom: 16px; 
  right: 16px;
  width:100px;
}